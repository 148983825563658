import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { useMutation, UseMutationResult, useQuery, useQueryClient } from "react-query";
import { Placeholder, Card, Col, Row, Container, Stack, Button, Modal, Form, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRecoilState } from "recoil";

import { SiteData } from "../../types/SiteData";
import { Domain } from "../../types/Domain";

import DomainEntry from "../../components/DomainEntry";
import { FilterState } from "../../store/FilterState";
import ApiService from "../../services/ApiService";

const DomainsPage = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [showModal, setShowModal] = useState(false);
	const [filterState, setFilterState] = useRecoilState(FilterState);
	const { isLoading, data, isFetching } = useQuery<AxiosResponse<Domain[]>, AxiosError>('sitesData', ApiService.sites.fetchSites);
	const { register, handleSubmit, watch, reset: resetAddSiteForm } = useForm<SiteData>()
	const { isLoading: addDomainLoading, isError, error: addSiteError, mutateAsync: addSite } = useMutation<AxiosResponse<UseMutationResult>, AxiosError<any>, SiteData>(ApiService.sites.addSite);

	const handleAddNewDomain = () => {
		navigate('/dashboard/domains/new');
	}

	const handleClose = () => {
		resetAddSiteForm();
		setShowModal(false);
	}

	const handleAddDomain = handleSubmit(async (formData) => {
		try {
			await addSite(formData);
			handleClose();
			await queryClient.invalidateQueries('sitesData');
		} catch (err) {
			console.error(err);
		}
	});

	const handleSort = (a: Domain, b: Domain) => {
		return (a?.displayname || '').localeCompare(b?.displayname || '');
	}

	const handleOnShowUpdatableDomainsOnly: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		setFilterState({
			...filterState,
			showUpdatesOnly: event.target.checked,
		});
	}

	const handleOnSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		setFilterState({
			...filterState,
			searchTerm: event.target.value.trim(),
		});
	}

	return (
		<Fragment>
			<Container className="mt-4 px-2" fluid={true}>
				<Stack direction="horizontal" className="mb-2" gap={3}>
					<h1 className="flex-grow-1">{data?.data.length} Domain{data?.data.length !== 1 ? 's' : ''}</h1>
					<Button
						variant="success"
						size="sm"
						className="bg-gradient"
						disabled={isLoading || isFetching || addDomainLoading}
						onClick={() => handleAddNewDomain()}
					>
						<FontAwesomeIcon icon="plus" className="me-2" />
						Add new Domain
					</Button>

					<Modal
						show={showModal}
						centered={true}
						onHide={() => handleClose()}>
						<Modal.Header closeButton>
							<Modal.Title>New Domain</Modal.Title>
						</Modal.Header>
						<Form onSubmit={handleAddDomain}>
							<Modal.Body>
								{isError && (
									<Alert variant="danger p-2 bg-gradient">{addSiteError?.response?.data?.error?.details[0]?.message || addSiteError?.response?.data?.data[0]}</Alert>
								)}
								<Form.Group className="mb-3">
									<Form.Label>Domain</Form.Label>
									<Form.Control
										{...register('domain')}
										required={true}
										placeholder="example.com" />
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Software</Form.Label>
									<Form.Select
										{...register('software')}
										aria-label="software">
										<option value="typo3">TYPO3</option>
									</Form.Select>
								</Form.Group>
							</Modal.Body>
							<Modal.Footer>
								<Button variant="none" size="sm" disabled={addDomainLoading} className="bg-gradient" onClick={handleClose}>Close</Button>
								<Button variant="tertiary" size="sm" disabled={addDomainLoading || !watch('domain')} type="submit" className="bg-gradient">
									{addDomainLoading && (
										<Spinner
											animation="border"
											as="span"
											size="sm"
											aria-hidden="true"
											className="me-2 position-relative"
											style={{ top: 2 }}
										/>
									)}
									Save domain
								</Button>
							</Modal.Footer>
						</Form>
					</Modal>
				</Stack>
				<Stack direction="vertical" className="mt-2 mb-4 flex-grow-1">
					<Form.Check
						type="switch"
						checked={filterState.showUpdatesOnly}
						id="showUpdatesOnly"
						onChange={handleOnShowUpdatableDomainsOnly}
						label="Show updatable domains only"
					/>
					<Form.Group className="mt-4 mb-3" controlId="searchForm">
						<Form.Control
							value={filterState.searchTerm}
							onChange={handleOnSearch}
							placeholder="Search domain name" />
					</Form.Group>
				</Stack>
				<Row className="justify-content-md-center">
					<Col xs={12} className="mb-4">
						<Row xs={1} lg={2} xxl={3}>
							{isLoading ? (
								<Fragment>
									{[1, 2, 3].map((value) => (
										<Col key={`${value}-placeholder`} className="mb-4 d-flex flex-column">
											<Card className="flex-grow-1" style={{ minHeight: '150px' }}>
												<Card.Body>
													<Placeholder animation="glow">
														<Stack direction="vertical" className="justify-content-between" style={{ height: '100%' }}>
															<h3>
																<Stack direction="horizontal" gap={2}>
																	<Placeholder xs={1} />
																	<Placeholder xs={6} />
																</Stack>
															</h3>
															<p className="mb-0"><Placeholder xs={4} /><Placeholder xs={12} /></p>
														</Stack>
													</Placeholder>
												</Card.Body>
											</Card>
										</Col>
									))}
								</Fragment>
							) : (
								<Fragment>
									{data?.data && data.data.length > 0 ? (
										<Fragment>
												{data.data.sort(handleSort).filter((item) => {
													if (filterState.searchTerm.length > 2) {
														return item.displayname.includes(filterState.searchTerm);
													}
													return true;
												}).map((domainEntry) => {
												return (
													<DomainEntry key={domainEntry.id} domainEntry={domainEntry} />
												)
											})}
										</Fragment>
									) : (
										<h5>No Domains found</h5>
									)}
								</Fragment>
							)}
						</Row>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default DomainsPage;
