import { AxiosError, AxiosResponse } from "axios";
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import isValidDomain from 'is-valid-domain';
import { Col, Row, Container, Stack, Button, Alert, Form, Spinner, Card, InputGroup } from 'react-bootstrap';
import { SiteData } from "../../../types/SiteData";
import { Domain } from "../../../types/Domain";

import ApiService from "../../../services/ApiService";

const NewDomainPage: React.FC = () => {
	const navigate = useNavigate();
	const [nextStepAvailable, setNextStepAvailable] = useState(false);
	const { register, handleSubmit, watch, setValue } = useForm<SiteData>();
	const { isLoading: addDomainLoading, isError, error: addSiteError, mutateAsync: addSite } = useMutation<AxiosResponse<Domain>, AxiosError<any>, SiteData>(ApiService.sites.addSite);

	const handleAddDomain = handleSubmit(async (formData) => {
		try {
			const response = await addSite(formData);
			if (response?.data?.id) {
				navigate(`./step-2/${response?.data?.id}`);
			}
		} catch (err) {
			console.error(err);
		}
	});

	const transformURL = (domainInput: string | undefined) => {
		if (!!domainInput && domainInput.includes('http')) {
			let domain = new URL(domainInput);
			setValue('domain', domain.hostname);
		}
	}

	const handleFormChanged = () => {
		transformURL(watch('domain'));
		setNextStepAvailable((isValidDomain(watch('domain') ?? '') && watch('domain') !== '' && watch('software') !== ''));
	}

	return (
		<Fragment>
			<Container className="mt-4 px-2" fluid={true}>
				<Stack direction="horizontal" className="mb-2" gap={3}>
					<h1 className="flex-grow-1">Add new Domain</h1>
				</Stack>
				<Row className="justify-content-md-center">
					<Col xs={12} className="mb-4">
						{isError && (
							<Alert variant="danger p-2 bg-gradient">{addSiteError?.response?.data?.data?.data?.error.description}</Alert>
						)}
						<Card>
							<Form onSubmit={handleAddDomain} onChange={handleFormChanged}>
								<Card.Body>
									<Form.Label htmlFor="domain">Domain</Form.Label>
									<InputGroup className="mb-3">
										<InputGroup.Text>https://</InputGroup.Text>
										<Form.Control
											{...register('domain', { required: true })}
											id="domain"
											name="domain"
											required={true}
											placeholder="example.com" />
									</InputGroup>
									<Form.Group className="mb-3">
										<Form.Label>Software</Form.Label>
										<Form.Select
											{...register('software', { required: true })}
											defaultValue="typo3"
											aria-label="software">
											<option value="">Please select</option>
											<option value="typo3">TYPO3</option>
											<option value="shopware6">Shopware 6</option>
											<option value="wordpress">Wordpress</option>
										</Form.Select>
									</Form.Group>
								</Card.Body>
								<Card.Footer>
									<Button disabled={!nextStepAvailable} variant="tertiary" size="sm" className="me-2 bg-gradient" type="submit">
										{addDomainLoading && (
											<Spinner
												animation="border"
												as="span"
												size="sm"
												aria-hidden="true"
												className="me-2 position-relative"
												style={{ top: 2 }}
											/>
										)}
										Next Step
									</Button>
								</Card.Footer>
							</Form>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default NewDomainPage;
