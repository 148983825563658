import React from "react"
import { Extension } from "../types/Extension";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ExtensionName: React.FC<{
    extension: Extension;
}> = ({ extension }) => {
    return (
        <>
            {extension.insecure === 1 && (
                <FontAwesomeIcon className="d-inline-block mt-2 me-2" icon="exclamation-circle" size="sm"/>
            )}
            {extension?.extension}
        </>
    );
}

export const ExtensionLink: React.FC<{
    extension: Extension;
}> = ({ extension }) => {

    const extensionName = <ExtensionName extension={extension}/>;

    if (!extension.link) {
        return extensionName;
    }

    return (
        <a href={extension.link} target="_blank" rel="noreferrer">{extensionName}</a>
    );
};
