import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';

const queryClient = new QueryClient();

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);
root.render(
	<QueryClientProvider client={queryClient}>
		<ToastContainer
			position="top-right"
			hideProgressBar={true}
			closeOnClick={true}
			draggable={true}
			theme="colored"
			autoClose={3000}
		/>
		<RecoilRoot>
			<App/>
		</RecoilRoot>
	</QueryClientProvider>
);
