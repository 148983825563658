import { Fragment } from "react";
import { Col, Row, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OverviewPage = () => {
	return (
		<Fragment>
			<Row className="mt-4 justify-content-md-center">
				<Col xs={12}>
					<Row xs={1} lg={3}>
						<Col>
							<Card className="mb-4 bg-danger text-white">
								<Card.Body>
									<h3>
										<FontAwesomeIcon className="d-inline-block" icon="globe-europe" size="sm"/>
										<span className="ms-2">Demo und so</span>
									</h3>
									<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque eos ipsum iure! Aliquid animi delectus dignissimos est eveniet fuga ipsum laudantium quae sapiente ullam? Assumenda dolores illum nemo odio unde.</p>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card className="mb-4">
								<Card.Body>
									<h3>
										<FontAwesomeIcon className="d-inline-block" icon="arrow-right" size="sm"/>
										<span className="ms-2">Demo und so</span>
									</h3>
									<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque eos ipsum iure! Aliquid animi delectus dignissimos est eveniet fuga ipsum laudantium quae sapiente ullam? Assumenda dolores illum nemo odio unde.</p>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card className="mb-4 bg-warning text-white">
								<Card.Body>
									<h3>
										<FontAwesomeIcon className="d-inline-block" icon="facebook-messenger" size="sm"/>
										<span className="ms-2">Demo und so</span>
									</h3>
									<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque eos ipsum iure! Aliquid animi delectus dignissimos est eveniet fuga ipsum laudantium quae sapiente ullam? Assumenda dolores illum nemo odio unde.</p>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Col>
			</Row>
		</Fragment>
	);
};

export default OverviewPage;
