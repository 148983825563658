import { useState } from "react";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { Auth } from "../types/Auth";

interface useAxiosConfig {
	axiosInstance?: AxiosInstance | any;
	method?: string;
	url?: string;
	requestConfig?: any;
}

export const getAxiosInstance = (withToken: boolean = true, axiosConfig?: AxiosRequestConfig): AxiosInstance => {
	const BASE_URL = `${process.env.REACT_APP_MAMO_BACKEND_SERVER}`;
	const authState: Auth | null = localStorage.getItem('auth') ? JSON.parse(`${localStorage.getItem('auth')}`) : null;

	if (withToken && authState) {
		return axios.create({
			baseURL: BASE_URL,
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${authState.jwt}`
			},
			...axiosConfig
		});
	}

	return axios.create({
		baseURL: BASE_URL,
		headers: {'Content-Type': 'application/json'},
		...axiosConfig
	});
}

type useAxiosReturnValues = {
	response?: AxiosResponse;
	error?: any | null | undefined;
	loading: boolean;
	abortController: AbortController;
	fetchData: (configObj: useAxiosConfig) => Promise<void>;
}

const useAxios = (): useAxiosReturnValues => {
	const [response, setResponse] = useState<any>([]);
	const [error, setError] = useState<Error | null>();
	const [loading, setLoading] = useState<boolean>(false);
	const [abortController, setAbortController] = useState<AbortController>(new AbortController());

	const fetchData = async (configObj: useAxiosConfig) => {
		setAbortController(new AbortController());
		const {
			axiosInstance = getAxiosInstance(),
			method = 'get',
			url,
			requestConfig = {}
		} = configObj;

		setError(null);

		try {
			setLoading(true);
			const res = await axiosInstance[method?.toLowerCase()](url, {
				...requestConfig,
				signal: abortController.signal
			});
			setResponse(res.data);
		} catch (err: any) {
			console.dir(err);
			setError(err);
		} finally {
			setLoading(false);
		}
	}

	return {
		response,
		error,
		loading,
		abortController,
		fetchData,
	};
}

export default useAxios
