import { toast } from "react-toastify";
import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { AxiosError, AxiosResponse } from "axios";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation, UseMutationResult } from "react-query";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Form, InputGroup, Button, Alert, Spinner, Figure, Fade, Card } from "react-bootstrap";

import Logo from "../../assets/Logo.svg";
import { AuthState } from "../../store/AuthState";
import ApiService from "../../services/ApiService";

import { Auth } from "../../types/Auth";

import { LoginFormData } from "../../types/Login";

type NavigationState = {
	from: Location
}

const Login: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { handleSubmit, register, formState: { errors } } = useForm<LoginFormData>();
	const {isLoading, isError, error, data, mutateAsync: login}: UseMutationResult<AxiosResponse<Auth>, AxiosError<any>, LoginFormData> = useMutation(ApiService.auth.login);
	const setAuthState = useSetRecoilState(AuthState);

	useEffect(() => {
		const verifyUser = async () => {
			const { data: userStatus } = await ApiService.account.verify();
			if (userStatus?.status === 'logged in') {
				const userData = localStorage.getItem('auth');
				if (!!userData) {
					const authUserData = JSON.parse(userData);
					if (authUserData?.jwt) {
						loginUser({data: authUserData});
					}
				}
			} else {
				localStorage.removeItem('auth');
			}
		};
		verifyUser();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (data?.data?.jwt) {
			loginUser(data);
		}
		// eslint-disable-next-line
	}, [data, navigate]);

	const loginUser = (userData: any) => {
		const locationState = location.state as NavigationState;
		const { from } = locationState || { from: { pathname: "/" } }

		const {username, email, roles, jwt} = userData?.data;
		const authStateObject = {
			username,
			email,
			roles,
			jwt
		};
		setAuthState(authStateObject);
		localStorage.setItem('auth', JSON.stringify(authStateObject));

		// show success toast
		toast.success(`Welcome ${authStateObject.username}`);
		navigate(from, {replace: true});
	};

	const handleOnSubmit = handleSubmit(async (formData) => {
		try {
			await login(formData)
		} catch (err) {
			console.error(err);
		}
	});

	return (
		<main className="d-md-flex justify-content-md-center bg-gradient bg-dark">
			<section className="d-flex align-items-center my-5 mt-lg-5 mb-lg-5">
				<Container>
					<Row className="justify-content-center form-bg-image">
						<Col xs={12} className="d-flex align-items-center justify-content-center">
							<div className="bg-white shadow-lg border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
								<div className="text-center text-md-center mb-4 mt-md-0">
									<Figure.Image
										width={160}
										src={Logo}
										className="mb-4"
									/>
									<h3 className="mb-0">Sign in to Mamo Dashboard</h3>
								</div>

								<Fade in={isError} timeout={1000}>
									<div className={isError ? 'mb-4' : ''} style={{height: isError ? 'auto' : 0}}>
										<Alert variant="danger mb-0 p-2 bg-gradient" style={{height: isError ? 'auto' : 0}}>{error?.response?.data?.data?.error.description}</Alert>
									</div>
								</Fade>

								<Form onSubmit={handleOnSubmit}>
									<Form.Group id="username" className="mb-4">
										<Form.Label className={errors?.username && 'text-danger'}>Username*</Form.Label>
										<InputGroup>
											<InputGroup.Text>
												<FontAwesomeIcon icon="user"/>
											</InputGroup.Text>
											<Form.Control
												{...register('username', { required: true })}
												disabled={isLoading}
												autoFocus
												required
												placeholder="Username"/>
										</InputGroup>
									</Form.Group>
									<Form.Group>
										<Form.Group id="password" className="mb-4 mb-md-5">
											<Form.Label className={errors?.password && 'text-danger'}>Password*</Form.Label>
											<InputGroup>
												<InputGroup.Text>
													<FontAwesomeIcon icon="unlock-alt"/>
												</InputGroup.Text>
												<Form.Control
													{...register('password', { required: true })}
													disabled={isLoading}
													autoComplete="on"
													type="password"
													required
													placeholder="Password"/>
											</InputGroup>
										</Form.Group>
									</Form.Group>
									<Button variant="tertiary" disabled={isLoading} type="submit" className="w-100 bg-gradient">
										{isLoading && (
											<Spinner
												animation="border"
												as="span"
												size="sm"
												aria-hidden="true"
												className="me-2"
											/>
										)}
										Sign in
									</Button>
								</Form>

								<div className="d-flex justify-content-center align-items-center mt-4">
									<span className="fw-normal">
										Not registered?
										<Card.Link as={Link} to="/register" className="fw-bold">
											{` Create account `}
										</Card.Link>
									</span>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</main>
	);
};

export default Login;
