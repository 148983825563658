import React from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav, Navbar, Dropdown, Container } from 'react-bootstrap';

import { AuthState } from "../store/AuthState";

const User = () => {
	const auth = useRecoilValue(AuthState);
	return (
		<span className="mb-0 font-small fw-bold">{auth.username}</span>
	);
};

const NavBar = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const setAuthState = useSetRecoilState(AuthState);

	const handleLogout = () => {
		setAuthState({
			username: '',
			email: '',
			roles: [],
			jwt: ''
		});
		localStorage.removeItem('auth');

		// show success toast
		toast.info("Bye bye");
		navigate('/login');
	};

	return (
		<Navbar className="d-none d-md-block position-sticky top-0 border-bottom bg-white shadow-soft" style={{zIndex: 100, marginLeft: '-16px', marginRight: '-16px'}}>
			<Container fluid className="px-3">
				<div className="d-flex w-100 justify-content-end">
					<Nav className="align-items-center">
						<Dropdown as={Nav.Item}>
							<Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
								<div className="media d-flex align-items-center">
									<div className="media-body ms-2 text-dark align-items-center d-none d-md-block">
										<User/>
									</div>
								</div>
							</Dropdown.Toggle>
							<Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
								{/*
								<Dropdown.Item className="fw-bold">
									<FontAwesomeIcon icon={faUserCircle} className="me-2"/> My Profile
								</Dropdown.Item>
								<Dropdown.Item className="fw-bold">
									<FontAwesomeIcon icon={faCog} className="me-2"/> Settings
								</Dropdown.Item>
								<Dropdown.Item className="fw-bold">
									<FontAwesomeIcon icon={faEnvelopeOpen} className="me-2"/> Messages
								</Dropdown.Item>

								*/}
								<Dropdown.Item className={`fw-bold ${location.pathname === '/profile/edit' ? 'active' : ''}`} onClick={() => navigate('/profile/edit')}>
									<FontAwesomeIcon icon="user-shield" className="me-2"/> Edit profile
								</Dropdown.Item>
								<Dropdown.Divider/>
								<Dropdown.Item className="fw-bold" onClick={() => handleLogout()}>
									<FontAwesomeIcon icon="sign-out-alt" className="text-danger me-2"/> Logout
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Nav>
				</div>
			</Container>
		</Navbar>
	);
};

export default NavBar;
