import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import Unauthorized from "./Unauthorized";
import { AuthState } from "../store/AuthState";
import ApiService from "../services/ApiService";

type ProtectedRouteProps = {
	allowedRoles: number[]
}

const ProtectedRoute = ({allowedRoles}: ProtectedRouteProps) => {
	let [auth, setAuthState] = useRecoilState(AuthState);
	const navigate = useNavigate();
	const location = useLocation();

	const logoutUser = () => {
		setAuthState({
			username: '',
			email: '',
			roles: [],
			jwt: ''
		});
		localStorage.removeItem('auth');
		navigate('/login');
	};

	useEffect(() => {
		const verifyUser = async () => {
			try {
				await ApiService.account.verify();
			} catch (error) {
				logoutUser();
			}
		};
		verifyUser();
		// eslint-disable-next-line
	}, [location]);

	// Redirect them to the /login page, but save the current location they were
	// trying to go to when they were redirected. This allows us to send them
	// along to that page after they login.
	return (
		auth?.roles.find(role => allowedRoles?.includes(parseInt(role)))
			? <Outlet/>
			: (!auth.username || !auth.jwt)
				? <Navigate to="/login" state={{from: location}} replace={true}/>
				: <Unauthorized/>
	)
}

export default ProtectedRoute;
