import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";

const Unauthorized = () => {
    return (
        <main>
            <section className="vh-100 d-flex align-items-center justify-content-center">
                <Container>
                    <Row>
                        <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
                            <div>
                                <h1 className="text-primary mt-5">
                                    You have <span className="fw-bolder">no access.</span>
                                </h1>
                                <p className="lead my-4">
                                    Oops! Looks like you are not allowed to access this page. If you think this is a
                                    problem with us, please tell us.
                                </p>
                                <Link to="/login" className="animate-hover bg-gradient btn btn-primary">
                                    <FontAwesomeIcon icon="chevron-right" className="animate-right-3 ms-0 me-2" />
                                    Go back
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};

export default Unauthorized;
