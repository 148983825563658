import React, { Fragment, PropsWithChildren } from "react";

import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Footer from "./Footer";

const RouteWithLoader: React.FC<PropsWithChildren<{ component: React.ElementType }>> = ({component: Component, ...rest}) => {
	return (
		<Fragment>
			<Component {...rest} />
		</Fragment>
	);
};

const RouteWithSidebar: React.FC<PropsWithChildren<{ component: React.ElementType }>> = ({component: Component, ...props}) => {
	return (
		<Fragment>
			<Sidebar/>
			<main className="content">
				<Navbar/>
				<Component {...props} />
				<Footer/>
			</main>
		</Fragment>
	);
};

export {
	RouteWithLoader,
	RouteWithSidebar
}
