import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Form, InputGroup, Button, Spinner, Figure, Card, Alert, Fade } from "react-bootstrap";
import { useMutation, UseMutationResult } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";

import Logo from "../../assets/Logo.svg";
import { RegisterFormData } from "../../types/Register";
import ApiService from "../../services/ApiService";

const Register: React.FC = () => {
	const navigate = useNavigate();
	const {handleSubmit, register} = useForm<RegisterFormData>();
	const {isLoading, data, isError, error, mutateAsync: registerUser}: UseMutationResult<AxiosResponse<any>, AxiosError<any>, RegisterFormData> = useMutation(ApiService.auth.register);

	useEffect(() => {
		if (data) {
			// show success toast
			toast.success("Registration successfull. Please check your E-Mails");
			navigate('/login', { replace: true });
		}
	}, [data, navigate]);

	const handleOnSubmit = handleSubmit(async (formData) => {
		// create account
		try {
			await registerUser(formData);
		} catch (err) {
			console.error(err);
		}
	});

	return (
		<main className="d-md-flex justify-content-md-center bg-gradient bg-dark">
			<section className="d-flex align-items-center my-5 mt-lg-5 mb-lg-5">
				<Container>
					<Row className="justify-content-center form-bg-image">
						<Col xs={12} className="d-flex align-items-center justify-content-center">
							<div className="bg-white shadow-lg border rounded border-light p-4 p-lg-5 w-100 fmw-sm-500 fmw-md-600 fmw-lg-800">
								<div className="text-center text-md-center mb-4 mt-md-0">
									<Figure.Image
										width={160}
										src={Logo}
										className="mb-4"
									/>
									<h3 className="mb-0">Sign up to Mamo Dashboard</h3>
								</div>

								<Fade in={isError} timeout={1000}>
									<div className={isError ? 'mb-4' : ''} style={{height: isError ? 'auto' : 0}}>
										<Alert variant="danger mb-0 p-2 bg-gradient" style={{height: isError ? 'auto' : 0}}>{error?.response?.data?.error?.details[0]?.message}</Alert>
									</div>
								</Fade>

								<Form onSubmit={handleOnSubmit}>
									<Row xs={1} md={2}>
										<Col>
											<Form.Group>
												<Form.Group id="firstname" className="mb-4">
													<Form.Label>Firstname*</Form.Label>
													<InputGroup>
														<Form.Control
															{...register('firstName')}
															autoComplete="on"
															disabled={isLoading}
															required
															placeholder="Firstname"/>
													</InputGroup>
												</Form.Group>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group>
												<Form.Group id="lastname" className="mb-4">
													<Form.Label>Lastname*</Form.Label>
													<InputGroup>
														<Form.Control
															{...register('lastName')}
															disabled={isLoading}
															autoComplete="on"
															required
															placeholder="Lastname"/>
													</InputGroup>
												</Form.Group>
											</Form.Group>
										</Col>
									</Row>
									<Form.Group>
										<Form.Group id="company" className="mb-4">
											<Form.Label>Company</Form.Label>
											<InputGroup>
												<InputGroup.Text>
													<FontAwesomeIcon icon="building"/>
												</InputGroup.Text>
												<Form.Control
													{...register('company')}
													disabled={isLoading}
													autoComplete="on"
													placeholder="Company"/>
											</InputGroup>
										</Form.Group>
									</Form.Group>
									<Form.Group id="email" className="mb-4">
										<Form.Label>Username*</Form.Label>
										<InputGroup>
											<InputGroup.Text>
												<FontAwesomeIcon icon="user"/>
											</InputGroup.Text>
											<Form.Control
												{...register('username')}
												disabled={isLoading}
												autoFocus
												required
												placeholder="Username"/>
										</InputGroup>
									</Form.Group>
									<Form.Group>
										<Form.Group id="password" className="mb-4">
											<Form.Label>Password*</Form.Label>
											<InputGroup>
												<InputGroup.Text>
													<FontAwesomeIcon icon="unlock-alt"/>
												</InputGroup.Text>
												<Form.Control
													{...register('password')}
													disabled={isLoading}
													autoComplete="on"
													required
													type="password"
													placeholder="Password"/>
											</InputGroup>
										</Form.Group>
									</Form.Group>
									<Form.Group>
										<Form.Group id="email" className="mb-4">
											<Form.Label>E-Mail*</Form.Label>
											<InputGroup>
												<InputGroup.Text>
													<FontAwesomeIcon icon="envelope"/>
												</InputGroup.Text>
												<Form.Control
													{...register('email')}
													disabled={isLoading}
													autoComplete="on"
													required
													type="email"
													placeholder="E-Mail"/>
											</InputGroup>
										</Form.Group>
									</Form.Group>
									<Button variant="tertiary" type="submit" className="w-100 bg-gradient mt-md-5">
										{isLoading && (
											<Spinner
												animation="border"
												as="span"
												size="sm"
												aria-hidden="true"
												className="me-2"
											/>
										)}
										Sign up
									</Button>
								</Form>

								<div className="d-flex justify-content-center align-items-center mt-4">
									<span className="fw-normal">
										Already registered?
										<Card.Link as={Link} to="/login" className="fw-bold">
											{` Login `}
										</Card.Link>
									</span>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</main>
	);
};

export default Register;
