import { getAxiosInstance } from "../hooks/useAxios";
import { SiteData } from "../types/SiteData";
import { RegisterFormData } from "../types/Register";
import { UserFormData } from "../types/Account";
import { LoginFormData } from "../types/Login";

const ApiService = {
	auth: {
		login: async ({ username, password }: LoginFormData) => {
			let axiosInstance = getAxiosInstance(false);
			const response = await axiosInstance.post('/v1/auth', { username, password });
			return response.data;
		},
		register: async ({ username, password, company, firstName, lastName, email }: RegisterFormData) => {
			let axiosInstance = getAxiosInstance(false);
			const response = await axiosInstance.post('/v1/register', { username, password, company, firstName, lastName, email });
			return response.data;
		},
		activateUser: async (token: string) => {
			let axiosInstance = getAxiosInstance();
			const response = await axiosInstance.post(`/v1/register/${token}`);
			return response.data;
		},
	},
	account: {
		show: async () => {
			let axiosInstance = getAxiosInstance();
			const response = await axiosInstance.get('/v1/dashboard/account/');
			return response.data;
		},
		update: async ({ firstName, lastName, company, email }: UserFormData) => {
			let axiosInstance = getAxiosInstance();
			const response = await axiosInstance.put('/v1/dashboard/account/', { company, firstName, lastName, email });
			return response.data;
		},
		verify: async () => {
			let axiosInstance = getAxiosInstance();
			const response = await axiosInstance.get('/v1/dashboard/verify');
			return response.data;
		},
	},
	sites: {
		fetchSites: async () => {
			let axiosInstance = getAxiosInstance();
			const response = await axiosInstance.get('/v1/dashboard/sites/');
			return response.data;
		},
		addSite: async (siteData: SiteData) => {
			let axiosInstance = getAxiosInstance();
			const response = await axiosInstance.post('/v1/dashboard/sites/', siteData);
			return response.data;
		},
		updateSite: async ({ id, software, active, infos, validationType }: SiteData) => {
			let axiosInstance = getAxiosInstance();
			const response = await axiosInstance.put(`/v1/dashboard/sites/${ id }`, { software, active, infos, validationType });
			return response.data;
		},
		checkSiteSecurity: async (siteId: number) => {
			let axiosInstance = getAxiosInstance();
			const response = await axiosInstance.get(`/v1/dashboard/sites/${siteId}/check`);
			return response.data;
		},
		verifySiteKey: async (siteId: number) => {
			let axiosInstance = getAxiosInstance();
			const response = await axiosInstance.get(`/v1/dashboard/sites/${siteId}/verify`);
			return response.data;
		},
		showSiteDetails: async (siteId: number) => {
			let axiosInstance = getAxiosInstance();
			const response = await axiosInstance.get(`/v1/dashboard/sites/${siteId}`);
			return response.data;
		},
		deleteSite: async (siteId: number) => {
			let axiosInstance = getAxiosInstance();
			const response = await axiosInstance.delete(`/v1/dashboard/sites/${siteId}`);
			return response.data;
		},
	}
}

export default ApiService;
