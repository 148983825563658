import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Form, InputGroup, Button, Spinner, Stack, Placeholder } from "react-bootstrap";
import { useMutation, UseMutationResult, useQuery, useQueryClient } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";

import { UserData, UserFormData } from "../../types/Account";
import ApiService from "../../services/ApiService";

const EditProfile: React.FC = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [userState, setUserState] = useState<UserData>({
		firstName: '',
		lastName: '',
		company: '',
		username: '',
		password: '',
		email: '',
	});
	const {handleSubmit, register} = useForm<UserFormData>();
	const {isLoading: loadingProfileData, data: profileData, isError: isProfileDataError} = useQuery('profileData', ApiService.account.show);
	const {isLoading, data, mutateAsync: updateUser}: UseMutationResult<AxiosResponse<any>, AxiosError, UserFormData> = useMutation(ApiService.account.update);

	useEffect(() => {
		const userData = {...profileData?.data[0][0]};
		setUserState(userData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingProfileData]);

	useEffect(() => {
		if (isProfileDataError) {
			// show error toast => redirect
			toast.error("Profile data couldn't be loaded.");
			navigate('/');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isProfileDataError]);

	const handleOnSubmit = handleSubmit(async (formData) => {
		// @ts-ignore
		const keys = Object.keys(formData) as const;
		let userData = {
			...userState
		};
		keys.map((key) => {
			// @ts-ignore
			if(formData[key]) {
				// @ts-ignore
				userData[key] = formData[key];
			}
			return userData;
		});

		try {
			// update account
			await updateUser(userData);
			// invalidate actual fetch to get fresh data on next fetch
			await queryClient.invalidateQueries('profileData');
			// show success toast
			toast.success("Profile data saved.");
		} catch (err) {
			console.error(err);
			// show errir toast
			toast.error("Something get wrong.");
		}
	});

	return (
		<Fragment>
			<Container className="mt-4 px-2" fluid={true}>
				<Stack direction="horizontal" className="mb-2" gap={3}>
					<h1 className="flex-grow-1">{data?.data?.site?.domain}</h1>
				</Stack>
				<Row className="justify-content-md-center">
					<Col xs={12} className="mb-4">
						{ loadingProfileData ? (
							<Fragment>
								<div className="mb-4">
									<Placeholder animation="glow">
										<Stack direction="vertical" gap={2}>
											<Placeholder xs={2}/>
											<h3><Placeholder xs={12}/></h3>
										</Stack>
									</Placeholder>
								</div>
								<Row xs={1} md={2} className="mb-4">
									<Col>
										<Placeholder animation="glow">
											<Stack direction="vertical" gap={2}>
												<Placeholder xs={4}/>
												<h3><Placeholder xs={12}/></h3>
											</Stack>
										</Placeholder>
									</Col>
									<Col>
										<Placeholder animation="glow">
											<Stack direction="vertical" gap={2}>
												<Placeholder xs={4}/>
												<h3><Placeholder xs={12}/></h3>
											</Stack>
										</Placeholder>
									</Col>
								</Row>
								<div className="mb-4">
									<Placeholder animation="glow">
										<Stack direction="vertical" gap={2}>
											<Placeholder xs={2}/>
											<h3><Placeholder xs={12}/></h3>
										</Stack>
									</Placeholder>
								</div>
								<div className="mb-4">
									<Placeholder animation="glow">
										<Stack direction="vertical" gap={2}>
											<Placeholder xs={2}/>
											<h3><Placeholder xs={12}/></h3>
										</Stack>
									</Placeholder>
								</div>
								{/*
								<div className="mb-4">
									<Placeholder animation="glow">
										<Stack direction="vertical" gap={2}>
											<Placeholder xs={2}/>
											<h3><Placeholder xs={12}/></h3>
										</Stack>
									</Placeholder>
								</div>
								*/}
							</Fragment>
							) : (
							<Form onSubmit={handleOnSubmit}>
								<Form.Group id="username" className="mb-4">
									<Form.Label>Username*</Form.Label>
									<InputGroup>
										<InputGroup.Text>
											<FontAwesomeIcon icon="user"/>
										</InputGroup.Text>
										<Form.Control
											value={userState.username}
											readOnly={true}
											disabled={true}
											autoFocus
											placeholder="Username"/>
									</InputGroup>
								</Form.Group>
								<Row xs={1} md={2}>
									<Col>
										<Form.Group>
											<Form.Group id="firstName" className="mb-4">
												<Form.Label>Firstname*</Form.Label>
												<InputGroup>
													<Form.Control
														{...register('firstName')}
														defaultValue={userState.firstName}
														autoComplete="on"
														disabled={isLoading}
														required
														placeholder="Firstname"/>
												</InputGroup>
											</Form.Group>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group>
											<Form.Group id="lastName" className="mb-4">
												<Form.Label>Lastname*</Form.Label>
												<InputGroup>
													<Form.Control
														{...register('lastName')}
														defaultValue={userState.lastName}
														disabled={isLoading}
														autoComplete="on"
														required
														placeholder="Lastname"/>
												</InputGroup>
											</Form.Group>
										</Form.Group>
									</Col>
								</Row>
								<Form.Group>
									<Form.Group id="company" className="mb-4">
										<Form.Label>Company</Form.Label>
										<InputGroup>
											<InputGroup.Text>
												<FontAwesomeIcon icon="building"/>
											</InputGroup.Text>
											<Form.Control
												{...register('company')}
												defaultValue={userState.company}
												disabled={isLoading}
												autoComplete="on"
												placeholder="Company"/>
										</InputGroup>
									</Form.Group>
								</Form.Group>
								{/*
								<Form.Group>
									<Form.Group id="password" className="mb-4">
										<Form.Label>Password*</Form.Label>
										<InputGroup>
											<InputGroup.Text>
												<FontAwesomeIcon icon={faUnlockAlt}/>
											</InputGroup.Text>
											<Form.Control
												{...register('password')}
												disabled={isLoading}
												autoComplete="on"
												type="password"
												placeholder="Password"/>
										</InputGroup>
									</Form.Group>
								</Form.Group>
								*/}
								<Form.Group>
									<Form.Group id="email" className="mb-4">
										<Form.Label>E-Mail*</Form.Label>
										<InputGroup>
											<InputGroup.Text>
												<FontAwesomeIcon icon="envelope"/>
											</InputGroup.Text>
											<Form.Control
												{...register('email')}
												defaultValue={userState.email}
												disabled={isLoading}
												autoComplete="on"
												required
												type="email"
												placeholder="E-Mail"/>
										</InputGroup>
									</Form.Group>
								</Form.Group>
								<Button variant="tertiary" type="submit" className="bg-gradient mt-md-3 float-end" disabled={isLoading}>
									{isLoading && (
										<Spinner
											animation="border"
											as="span"
											size="sm"
											aria-hidden="true"
											className="me-2"
										/>
									)}
									Update profile data
								</Button>
							</Form>
						)}
					</Col>
				</Row>
			</Container>
		{/*
		<main className="d-md-flex justify-content-md-center bg-gradient bg-dark">
			<section className="d-flex align-items-center my-5 mt-lg-5 mb-lg-5">
				<Container>
					<Row className="justify-content-center form-bg-image">
						<Col xs={12} className="d-flex align-items-center justify-content-center">
							<div className="bg-white shadow-lg border rounded border-light p-4 p-lg-5 w-100 fmw-sm-500 fmw-md-600 fmw-lg-800">
								<div className="text-center text-md-center mb-4 mt-md-0">
									<Figure.Image
										width={160}
										src={Logo}
										className="mb-4"
									/>
									<h3 className="mb-0">Update Profile</h3>
								</div>

								<Fade in={isError} timeout={1000}>
									<div className={isError ? 'mb-4' : ''} style={{height: isError ? 'auto' : 0}}>
										<Alert variant="danger mb-0 p-2 bg-gradient" style={{height: isError ? 'auto' : 0}}>{error?.response?.data?.error?.details[0]?.message}</Alert>
									</div>
								</Fade>


							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</main>*/}
		</Fragment>
	);
};

export default EditProfile;
