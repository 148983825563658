import React from 'react';
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from "@fortawesome/free-solid-svg-icons";

import { RouteWithSidebar } from './components/RoutingComponents';
import ProtectedRoute from "./components/ProtectedRoute";

import DomainsPage from './pages/Domains';
import LoginPage from './pages/Login';
import RegisterPage from './pages/Register';
import RegisterVerifyPage from './pages/Register/verify';
import OverviewPage from "./pages/Overview";
import DomainDetailPage from "./pages/Domains/Detail";
import EditProfile from "./pages/Account/EditProfile";

import NewDomainPage from "./pages/Domains/Steps/New";
import VerifyDomainPage from "./pages/Domains/Steps/Verify";

import { ROLES } from "./store/AuthState";

import './scss/volt.scss';

// @ts-ignore
library.add(fab, fas);

const App = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Navigate to="/dashboard/domains" replace={true}/>}/>
				<Route path="/dashboard" element={<Navigate to="/dashboard/domains" replace={true}/>}/>

				<Route path="/profile" element={<ProtectedRoute allowedRoles={[ROLES.USER]}/>}>
					<Route path="/profile/edit">
						<Route index element={<RouteWithSidebar component={EditProfile}/>}/>
					</Route>
				</Route>

				<Route path="/dashboard" element={<ProtectedRoute allowedRoles={[ROLES.USER]}/>}>
					<Route path="/dashboard/domains">
						<Route index element={<RouteWithSidebar component={DomainsPage}/>}/>
						<Route path="/dashboard/domains/details/:siteId" element={<RouteWithSidebar component={DomainDetailPage}/>}/>
						<Route path="/dashboard/domains/new" element={<RouteWithSidebar component={NewDomainPage}/>}/>
						<Route path="/dashboard/domains/new/step-2/:domainUid" element={<RouteWithSidebar component={VerifyDomainPage}/>}/>
					</Route>
					<Route path="/dashboard/overview" element={<RouteWithSidebar component={OverviewPage}/>}/>
				</Route>

				<Route path="/login" element={<LoginPage/>}/>
				<Route path="/register">
					<Route index element={<RegisterPage/>}/>
					<Route path="verify" element={<Navigate to="/register"/>}/>
					<Route path="verify/:token" element={<RegisterVerifyPage/>}/>
				</Route>
			</Routes>
			{process.env.NODE_ENV === 'development' && (
				<ReactQueryDevtools initialIsOpen={false}/>
			)}
		</BrowserRouter>
	)
};

export default App;
