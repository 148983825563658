import { AxiosError, AxiosResponse } from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, Container, Stack, Button, Spinner, Card, Alert, Form } from 'react-bootstrap';
import { toast } from "react-toastify";

import { SiteData, SiteDataResponse, UpdateSiteDataResponse, VerifySiteDataResponse } from "../../../types/SiteData";
import ApiService from "../../../services/ApiService";

const VerifyDomainPage: React.FC = () => {
	const { domainUid } = useParams();
	const navigate = useNavigate();
	const [validationType, setValidationType] = useState(1);
	const [siteChecking, setSiteChecking] = useState(false);
	const { isLoading, data: siteData, mutateAsync: getSite } = useMutation<AxiosResponse<SiteDataResponse>, AxiosError, number>(['siteDetails', domainUid], ApiService.sites.showSiteDetails);
	const { isLoading: isVerifying, data: verifyResult, mutateAsync: verifySiteKey } = useMutation<AxiosResponse<VerifySiteDataResponse>, AxiosError, number>(['verifySiteKey', domainUid], ApiService.sites.verifySiteKey);
	const { mutateAsync: updateSite } = useMutation<AxiosResponse<UpdateSiteDataResponse>, AxiosError, SiteData>(['updateSite', domainUid], ApiService.sites.updateSite);

	useEffect(() => {
		if (!domainUid) return;
		getSite(parseInt(domainUid));
	}, [domainUid, getSite]);

	useEffect(() => {
		if (siteData?.data?.site.validationType && siteData?.data?.site.validationType !== validationType) {
			setValidationType(siteData?.data?.site.validationType);
		}
	}, [siteData, validationType, setValidationType]);

	const handleCheckSiteVerification = async () => {
		if (!domainUid) return;
		setSiteChecking(true);

		try {
			let response = await verifySiteKey(parseInt(domainUid));
			// check if domain is connected to prometheus
			if (response.data?.check) {
				// activate domain entry and redirect to overview
				let response = await updateSite({ id: parseInt(domainUid), active: true });
				if (response.data.updated) {
					navigate('/dashboard/domains');
					// show success toast
					toast.success("Domain activated successfully.");
				} else {
					toast.error("Domain could not be activated.");
				}
			}
		} catch (error) {
			console.error(error);
		}

		setSiteChecking(false);
	}

	const handleChangeValidationType = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		setValidationType(parseInt(event.target.value));
		updateValidationType(parseInt(event.target.value));
	};

	const updateValidationType = async (validationType: SiteData['validationType']) => {
		if (!domainUid) return;
		try {
			let response = await updateSite({ id: parseInt(domainUid), validationType });
			if (response.data.updated) {
				toast.success("Domain validation type changed successfully.");
			} else {
				toast.error("Domain validation type could not be changed.");
			}
		} catch (err) {
			console.error(err);
		}
	}

	return (
		<Fragment>
			<Container className="mt-4 px-2" fluid={true}>
				<Stack direction="horizontal" className="mb-2" gap={3}>
					{!isLoading && (
						<h1 className="flex-grow-1">Verify your new Domain - {siteData?.data?.site?.domain}</h1>
					)}
				</Stack>
				{(!isVerifying && verifyResult?.data?.error?.description) && (
					<Alert variant="danger">{verifyResult?.data?.error?.description}</Alert>
				)}
				<Row className="justify-content-md-center">
					<Col xs={12} className="mb-4">
						{isLoading ? (
							<h1>Loading ...</h1>
						) : (
							<Card>
								<Card.Body>
									<p>Please add the activation key to the settings of the mobilistics prometheus_monitoring extension in your system.</p>
									<p>Activation key: <code>{siteData?.data?.site.key}</code></p>
									<div className="d-flex align-items-start">
										<p className="mt-1 me-2 mb-3">VerificationType: </p>
										<Form.Select
											onChange={handleChangeValidationType}
											aria-label="VerificationType"
											size="sm"
											defaultValue={validationType}
											style={{width:'auto', paddingRight: '30px'}}>
											<option value="0">GET</option>
											<option value="1">HMAC</option>
										</Form.Select>
									</div>
									<Button variant="success" size="sm" disabled={siteChecking} className="bg-gradient" onClick={handleCheckSiteVerification}>
										{siteChecking && (
											<Spinner
												animation="border"
												as="span"
												size="sm"
												aria-hidden="true"
												className="me-2 position-relative"
												style={{ width: 15, height: 15, top: 1 }}
											/>
										)}
										Check activation
									</Button>
								</Card.Body>
							</Card>
						)}
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default VerifyDomainPage;
