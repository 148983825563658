import React, { Fragment, PropsWithChildren, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Badge, Button, Card, Col, Dropdown, Fade, Form, Modal, Placeholder, Row, Spinner, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";

import ApiService from "../services/ApiService";
import { Domain } from "../types/Domain";
import { FilterState } from "../store/FilterState";

const SoftwareIcon: React.FC<PropsWithChildren<{ software: string }>> = ({ software }) => {
	switch (software) {
		case 'shopware6':
			return <FontAwesomeIcon className="d-inline-block mt-2" icon={['fab', 'shopware']} size="sm" />;
		case 'typo3':
			return <FontAwesomeIcon className="d-inline-block mt-2" icon={['fab', 'typo3']} size="sm" />;
		default:
			return <></>;
	}
}

const DomainEntry: React.FC<PropsWithChildren<{ domainEntry: Domain }>> = ({ domainEntry }) => {
	const filterState = useRecoilValue(FilterState);
	const queryClient = useQueryClient();
	const [showModal, setShowModal] = useState(false);
	const navigate = useNavigate();
	const [deleteDomainEntry, setDeleteDomainEntry] = useState(true);

	const { data, isLoading: loadingAdditionalSiteInformations } = useQuery(['siteSecurity', domainEntry.id], () => ApiService.sites.checkSiteSecurity(domainEntry.id));
	const { isLoading, mutateAsync: deleteSite } = useMutation(ApiService.sites.deleteSite);

	useEffect(() => {
		setDeleteDomainEntry(false);
	}, [setDeleteDomainEntry]);

	const handleDeleteClicked = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setShowModal(true);
		setDeleteDomainEntry(true);
		await deleteSite(domainEntry.id);
		handleClose();
		await queryClient.invalidateQueries('sitesData');
	}

	const handleClose = () => {
		setShowModal(false);
	}

	const handleClickCard = () => {
		if (domainEntry.active) {
			navigate(`details/${domainEntry.id}`);
		}
	}

	const handleActivateDomain = () => {
		if (!domainEntry.active) {
			navigate(`/dashboard/domains/new/step-2/${domainEntry.id}`);
		}
	}

	const handleChangeVeryficationType = () => {
		navigate(`/dashboard/domains/new/step-2/${domainEntry.id}`);
	}

	const copyTextToClipboard = async (textContent: string) => {
		if ('clipboard' in navigator) {
			await navigator.clipboard.writeText(textContent);
		} else {
			document.execCommand('copy', true, textContent);
		}
		toast.info('Secret key copied to clipboard');
	}

	// hide elements which had no updates or not active
	if ((filterState.showUpdatesOnly && domainEntry.active && (data?.data?.hasOwnProperty('minor_updates') && data?.data?.minor_updates < 1)) || (filterState.showUpdatesOnly && !data?.data?.minor_updates)) {
		return null;
	}

	return (
		<Col className="mb-4 d-flex flex-column">
			<Fade in={!deleteDomainEntry}>
				<Card className={`flex-grow-1 shadow-soft ${((data?.data?.hasOwnProperty('minor_updates') && data?.data?.minor_updates > 0) || (data?.data?.hasOwnProperty('is_online') && !data?.data?.is_online)) ? 'border-danger' : 'border-success'} ${domainEntry.active ? 'shadow-hover' : ''} ${data?.data?.hasOwnProperty('secure') ? (!data?.data?.secure && 'card-insecure') : ''} ${(data?.data?.hasOwnProperty('is_online') && !data?.data?.is_online) && 'card-insecure'}`}>
					<Card.Body className="d-flex flex-column justify-content-start">
						<Stack direction="horizontal" gap={3} className="align-items-start">
							<h3 className={`flex-grow-1 flex-shrink-1 hyphens card-heading ${domainEntry.active ? 'cursor-pointer' : ''}`} onClick={handleClickCard}>
								<Stack direction="horizontal" gap={1} className="align-items-start">
									{(data?.data?.hasOwnProperty('secure') && !data?.data?.secure) || (data?.data?.hasOwnProperty('is_online') && !data?.data?.is_online) ? (
										<FontAwesomeIcon className="d-inline-block mt-2" icon="exclamation-circle" size="sm" />
									) : (
										<SoftwareIcon software={data?.data?.software} />
									)}
									<span className="ms-2">{domainEntry.displayname}</span>
								</Stack>
							</h3>
							<Dropdown>
								<Dropdown.Toggle variant="none" id="dropdown-basic">
									<FontAwesomeIcon icon="angle-down" />
								</Dropdown.Toggle>

								<Dropdown.Menu align="end">
									{!domainEntry.active ? (
										<Dropdown.Item className="text-success fw-bold" onClick={handleActivateDomain}>
											<FontAwesomeIcon icon="check-circle" />
											<span className="ps-2">Domain aktivieren</span>
										</Dropdown.Item>
									) : (
										<Dropdown.Item className="fw-bold" onClick={() => copyTextToClipboard(domainEntry.key)}>
											<FontAwesomeIcon icon="key" />
											<span className="ps-2">Copy secret key</span>
										</Dropdown.Item>
									)}
									<Dropdown.Item className="fw-bold" onClick={handleChangeVeryficationType}>
										<FontAwesomeIcon icon="shield-halved" />
										<span className="ps-2">Change veryfication type</span>
									</Dropdown.Item>
									<Dropdown.Item className="text-danger fw-bold" onClick={() => setShowModal(true)}>
										<FontAwesomeIcon icon="trash" />
										<span className="ps-2">Delete</span>
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Stack>
						<Stack className="flex-grow-0">
							{((data?.data?.hasOwnProperty('version') && !!data?.data?.version) || (data?.data?.hasOwnProperty('uptime') && data?.data?.uptime)) ? (
								<div className="subinfo-box rounded-2 p-3 mt-2">
									<Row>
										{(data?.data?.hasOwnProperty('version') && !!data?.data?.version) && (
											<Col xs={12} sm={6}>
												<h6 className="mt-2 mb-0">{`${data?.data?.software}`.toUpperCase()} Version:</h6>
												<p className="mb-0">{data?.data?.version}</p>
											</Col>
										)}
										{(data?.data?.hasOwnProperty('uptime') && data?.data?.uptime) && (
											<Col xs={12} sm={6}>
												<h6 className="mt-2 mb-0">Uptime last 7 days:</h6>
												<p className="mb-0">{data?.data?.uptime}%</p>
											</Col>
										)}
									</Row>
								</div>
							) : (
								<Fragment>
									{(loadingAdditionalSiteInformations && !!domainEntry.active) && (
										<Placeholder animation="glow" className="subinfo-box rounded-2 p-3 mt-2">
											<Row>
												<Col xs={12} sm={6}>
													<Stack direction="vertical" className="justify-content-between" style={{ height: '100%' }}>
														<p className="mb-0"><Placeholder xs={10} /><br /><Placeholder xs={8} /></p>
													</Stack>
												</Col>
												<Col xs={12} sm={6}>
													<Stack direction="vertical" className="justify-content-between" style={{ height: '100%' }}>
														<p className="mb-0"><Placeholder xs={10} /><br /><Placeholder xs={8} /></p>
													</Stack>
												</Col>
											</Row>
										</Placeholder>
									)}
								</Fragment>
							)}

							{(data?.data?.hasOwnProperty('is_online') && !data?.data?.is_online) ? (
								<Badge bg="danger" pill={false} className="p-2 position-absolute" style={{ top: '-10px', right: '-10px' }}>Site is down</Badge>
							) : (
								<Fragment>
									{(data?.data?.hasOwnProperty('minor_updates') && data?.data?.minor_updates > 0) && (
										<Badge bg="danger" pill={false} className="p-2 position-absolute" style={{ top: '-10px', right: '-10px' }}>{`${data?.data?.minor_updates} Update${data?.data?.minor_updates !== 1 ? 's' : ''}`}</Badge>
									)}
								</Fragment>
							)}

							{!domainEntry.active && (
								<Button
									onClick={handleActivateDomain}
									variant="none"
									className="btn btn-outline-success btn-sm align-self-start mt-3">Domain aktivieren</Button>
							)}
						</Stack>
					</Card.Body>
				</Card>
			</Fade>

			<Modal
				show={showModal}
				centered={true}
				onHide={() => handleClose()}>
				<Modal.Header closeButton>
					<Modal.Title className="text-danger">Delete Domain</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleDeleteClicked}>
					<Modal.Body>Do you really want to delete this domain?</Modal.Body>
					<Modal.Footer>
						<Button variant="none" disabled={isLoading} size="sm" onClick={handleClose}>Close</Button>
						<Button variant="danger" disabled={isLoading} size="sm" type="submit" className="bg-gradient">
							{isLoading && (
								<Spinner
									animation="border"
									as="span"
									size="sm"
									aria-hidden="true"
									className="me-2 position-relative"
									style={{ top: 2 }}
								/>
							)}
							Delete
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Col>
	)
};

export default DomainEntry;
