import { AxiosError, AxiosResponse } from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Placeholder, Col, Row, Container, Stack, Table, Badge, Button} from 'react-bootstrap';
import { CKEditor, CKEditorEventPayload } from 'ckeditor4-react';

import ApiService from "../../services/ApiService";
import { Extension } from "../../types/Extension";
import { SiteData } from "../../types/SiteData";
import {ExtensionLink} from "../../components/ExtensionLink";

const DomainDetailPage: React.FC = () => {
	let timeOut: any;
	const navigate = useNavigate();
	const { siteId } = useParams();
	const [ckEditorConfig] = useState({
		autoGrow_minHeight: 250,
		autoGrow_maxHeight: 500,
		autoGrow_onStartup: true,
		extraPlugins: [
			'autogrow'
		],
		toolbar: [
			['Format', 'Bold', 'Italic'],
			['Link', 'Unlink'],
			['NumberedList', 'BulletedList'],
			['Paste', 'PasteText', 'PasteFromWord', '-', 'RemoveFormat', '-', 'Undo', 'Redo'],
			['Source']
		]
	});
	const { isLoading, data, error, mutateAsync: getSiteData } = useMutation<AxiosResponse<any>, AxiosError<{ data: { error: { description: string } } }>, number>(['siteDetails', siteId], ApiService.sites.showSiteDetails);
	const { mutateAsync: updateSiteData, isLoading: notesAreSaving } = useMutation<AxiosResponse<any>, AxiosError<{ data: { error: { description: string } } }>, SiteData>(['siteDetails', siteId], ApiService.sites.updateSite);
	const { data: additionalSiteData, refetch: refetchAdditionalSiteData } = useQuery(['siteSecurity', siteId], () => { if (siteId) return ApiService.sites.checkSiteSecurity(parseInt(siteId)) }, { enabled: false });


	const [editorData, setEditorData] = useState(data?.data?.site?.infos);

	useEffect(() => {
		if (!siteId) return;
		getSiteData(parseInt(siteId));
		refetchAdditionalSiteData();
	}, [siteId, getSiteData, refetchAdditionalSiteData]);

	useEffect(() => {
		// redirect to home and show error message
		if (error) {
			let errorMessage = 'Oops there was an error';
			if (error.response?.data?.data?.error?.description) {
				errorMessage = error.response?.data?.data?.error?.description;
			}
			toast.error(errorMessage);
			navigate('/');
		}
	}, [error, navigate]);

	const safeEditorData = async () => {
		try {
			await updateSiteData({
				id: data?.data?.site?.id,
				active: data?.data?.site?.active,
				software: data?.data?.site?.software,
				infos: editorData
			});
			toast.success("Data updated successfully.");
		} catch (error) {
			console.error(error);
		}
	}

	const handleOnChangeCkEditor = async (event: CKEditorEventPayload<'change'>) => {
		setEditorData(event.editor?.getData());

		clearTimeout(timeOut);
		timeOut = setTimeout(async () => {
			await safeEditorData();
		}, 1500);
	}

	return (
		<Fragment>
			<Container className="mt-4 px-2" fluid={true}>
				<Stack direction="horizontal" className="mb-2" gap={3}>
					{isLoading ? (
						<div className="flex-grow-1">
							<Placeholder animation="glow">
								<h2 className="mb-4"><Placeholder xs={3} /></h2>
								<h5 className="mb-2"><Placeholder xs={1} /></h5>
								<Table responsive striped bordered style={{ width: '100%', tableLayout: 'auto' }}>
									<thead>
										<tr>
											<th style={{ padding: '0.75rem 0.5rem' }} colSpan={2}><Placeholder xs={1} /></th>
											<th style={{ padding: '0.75rem 0.5rem' }}><Placeholder xs={12} /></th>
											<th style={{ padding: '0.75rem 0.5rem' }}><Placeholder xs={5} /></th>
											<th style={{ padding: '0.75rem 0.5rem' }}><Placeholder xs={4} /></th>
										</tr>
									</thead>
									<tbody>
										{['', '', '', ''].map((index) => (
											<tr key={index}>
												<td colSpan={2}><Placeholder xs={1} /></td>
												<td style={{ width: 100 }}><Placeholder xs={8} /></td>
												<td style={{ width: 200 }}><Placeholder xs={6} /></td>
												<td style={{ width: 150 }}><Placeholder xs={6} /></td>
											</tr>
										))}
									</tbody>
								</Table>
							</Placeholder>
						</div>
					) : (
						<h1 className="flex-grow-1">
							{additionalSiteData?.data?.hasOwnProperty('secure') && !additionalSiteData?.data?.secure ? (
								<FontAwesomeIcon className="d-inline-block mt-2 me-2" icon="exclamation-circle" size="sm" />
							) : (
								<FontAwesomeIcon className="d-inline-block mt-2 me-2" icon={['fab', 'typo3']} size="sm" />
							)}
							{data?.data?.site?.domain}
							{data?.data?.site?.domain && (
								<a href={`https://${data?.data?.site?.domain}`} target="_blank" rel="noreferrer noopener" title={`open ${data?.data?.site?.displayname} in a new tab`} aria-label={data?.data?.site?.displayname}>
									<FontAwesomeIcon className="d-inline-block ms-2" icon={['fas', 'external-link-alt']} size="xs" />
								</a>
							)}
							{additionalSiteData?.data?.minor_updates > 0 && (
								<Badge bg="danger" style={{ fontSize: '14px', top: '-7px' }} pill={false} className="position-relative ms-3 my-0 p-2">{`${additionalSiteData?.data?.minor_updates} Update${additionalSiteData?.data?.minor_updates !== 1 ? 's' : ''}`}</Badge>
							)}
						</h1>
					)}
				</Stack>
				<Row className="justify-content-md-center">
					<Col xs={12} className="mb-4">
						{!isLoading && (
							<Fragment>
								{(data && data?.data?.site?.hasOwnProperty('extensions') && data?.data?.site?.extensions?.length > 0) && (
									<Fragment>
										<h4>
											<span>Notes</span>
											{notesAreSaving && (
												<FontAwesomeIcon className="d-inline-block ms-2" spin={true} icon="spinner" size="xs" />
											)}
										</h4>
										<CKEditor
											onChange={handleOnChangeCkEditor}
											initData={data?.data?.site?.infos}
											config={ckEditorConfig}
										/>
										<div className="actions-wrapper d-flex justify-content-end" style={{ marginTop: "-36px", }}>
											<Button variant={"success"} onClick={safeEditorData}>Safe</Button>
										</div>
										<h4>About {`${data?.data?.site?.software}`.toUpperCase()}</h4>
										<Table responsive striped bordered style={{ width: '100%', tableLayout: 'auto' }}>
											<thead>
												<tr>
													<th style={{ padding: '0.75rem 0.5rem' }} colSpan={2}>Extension</th>
													<th style={{ padding: '0.75rem 0.5rem' }}>Current version</th>
													<th style={{ padding: '0.75rem 0.5rem' }}>Latest version</th>
													<th style={{ padding: '0.75rem 0.5rem' }}>Type</th>
												</tr>
											</thead>
											<tbody>
												<Fragment>
													{data && data?.data?.site?.extensions && data?.data?.site?.extensions?.map((extension: Extension, index: number) => (
														<Fragment key={`${index}`}>
															{(extension && extension?.extension) && (
																<tr key={`${extension?.type}-${index}`} className={`${extension?.version && extension?.latestVersion && (extension?.version !== extension?.latestVersion) ? 'bg-warning' : ''}${(extension.insecure === 1) ? ' bg-danger' : ''}`}>
																	<td colSpan={2} className={`${(extension.insecure === 1) ? 'text-white' : ''}`}>
																		<ExtensionLink extension={extension} />
																	</td>
																	<td style={{ width: 100 }} className={`${(extension.insecure === 1) ? 'text-white' : ''}`}>{extension?.version}</td>
																	<td style={{ width: 200 }} className={`${(extension.insecure === 1) ? 'text-white' : ''}`}>{extension?.insecure === 1 ? extension?.nextSecureVersion : extension?.latestVersion}</td>
																	<td style={{ width: 150 }} className={`${(extension.insecure === 1) ? 'text-white' : ''}`}>{extension?.type}</td>
																</tr>
															)}
														</Fragment>
													))}
												</Fragment>
											</tbody>
										</Table>
									</Fragment>
								)}
							</Fragment>
						)}
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default DomainDetailPage;
