import React, { Fragment, useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation, Link } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav, Badge, Image, Button, Navbar } from 'react-bootstrap';

import ReactHero from "../logo.svg";

import { NavItemProps } from "../types/Sidebar";

const Sidebar = () => {
	const location = useLocation();
	const { pathname } = location;
	const [show, setShow] = useState(false);
	const showClass = show ? "show" : "";

	const onCollapse = () => setShow(!show);

	const NavItem = (props: NavItemProps) => {
		const { title, link, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary", subRoutesActiveIndicator = false } = props;
		const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
		const navItemClassName = ((!subRoutesActiveIndicator && link === pathname) || (subRoutesActiveIndicator && pathname.indexOf(link) > -1)) ? "active" : "";

		return (
			<Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
				<Link to={link} target={target} className={`nav-link ${classNames}`}>
					<span>
						{icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
						{image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}
						<span className="sidebar-text">{title}</span>
					</span>
					{badgeText ? (
						<Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
					) : null}
				</Link>
			</Nav.Item>
		);
	};

	return (
		<Fragment>
			<Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
				<Navbar.Brand className="me-lg-5" as={Link} to="/">
					<Image src={ReactHero} className="navbar-brand-light" />
				</Navbar.Brand>
				<Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
					<span className="navbar-toggler-icon" />
				</Navbar.Toggle>
			</Navbar>
			<CSSTransition timeout={300} in={show} classNames="sidebar-transition">
				<SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
					<div className="sidebar-inner px-4 pt-3">
						<div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
							<div className="d-flex align-items-center">
								<div className="user-avatar lg-avatar me-4">
									<p>Profile Picture</p>
								</div>
								<div className="d-block">
									<h6>Hi, Jane</h6>
									<Link to="/" className="text-dark btn btn-secondary">
										<FontAwesomeIcon icon="sign-out-alt" className="me-2" /> Sign Out
									</Link>
								</div>
							</div>
							<Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
								<FontAwesomeIcon icon="times" />
							</Nav.Link>
						</div>
						<Nav className="flex-column pt-3 pt-md-0">
							<NavItem title="Mobi Mamo Dashboard" link="/" />
							<NavItem title="Domains" icon="globe-europe" link="/dashboard/domains" subRoutesActiveIndicator={true} />

							{/*
							<NavItem title="Overview" icon="chart-pie" link="/dashboard/overview"/>
							<NavItem title="Settings" icon="cog" link="/dashboard"/>
							<Dropdown.Divider className="my-3 border-indigo"/>

							<CollapsableNavItem eventKey="documentation/" title="Getting Started" icon={faBook}>
								<NavItem title="Overview" link="/documentation/Overview"/>
								<NavItem title="Download" link="/documentation/Download"/>
								<NavItem title="Quick Start" link="/documentation/Quick"/>
								<NavItem title="License" link="/documentation/License"/>
								<NavItem title="Folder Structure" link="/documentation/Folder"/>
								<NavItem title="Build Tools" link="/documentation/Build"/>
								<NavItem title="Changelog" link="/documentation/Changelog"/>
							</CollapsableNavItem>
							*/}
						</Nav>
					</div>
				</SimpleBar>
			</CSSTransition>
		</Fragment>
	);
};

export default Sidebar;
