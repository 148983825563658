import { AxiosError, AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, UseMutationResult } from "react-query";
import { Container, Row, Col, Spinner, Figure } from "react-bootstrap";

import Logo from "../../assets/Logo.svg";
import { RegiserVerifyPathData, RegiserVerifyPathDataResponse } from "../../types/Register";
import ApiService from "../../services/ApiService";
import { toast } from "react-toastify";

const RegisterVerify: React.FC = () => {
	const navigate = useNavigate();
	const params = useParams<Readonly<RegiserVerifyPathData>>();
	const {isLoading, data, mutateAsync: activateUser}: UseMutationResult<AxiosResponse<RegiserVerifyPathDataResponse>, AxiosError, string> = useMutation(ApiService.auth.activateUser);

	useEffect(() => {
		if (data) {
			if (data.data.updated === 1) {
				toast.success("User activation successfull");
			} else {
				toast.error("User activation failed");
			}
			navigate('/login', { replace: true });
		}
	}, [data, navigate]);

	useEffect(() => {
		const verifyUser = async (verifyPathData: string) => {
			// create account
			try {
				await activateUser(verifyPathData);
			} catch (err) {
				console.error(err);
			}
		};

		if(params.token) {
			verifyUser(params.token);
		}
		// eslint-disable-next-line
	}, [params])

	return (
		<main className="d-md-flex justify-content-md-center bg-gradient bg-dark">
			<section className="d-flex align-items-center my-5 mt-lg-5 mb-lg-5">
				<Container>
					<Row className="justify-content-center form-bg-image">
						<Col xs={12} className="d-flex align-items-center justify-content-center">
							<div className="bg-white shadow-lg border rounded border-light p-4 p-lg-5 w-100 fmw-sm-500 fmw-md-600 fmw-lg-800">
								<div className="text-center text-md-center mb-4 mt-md-0">
									<Figure.Image
										width={160}
										src={Logo}
										className="mb-4"
									/>
									<h3 className="mb-0">Verifying your account</h3>
								</div>


								<div className="d-flex justify-content-center align-items-center mt-4">
									{isLoading && (
										<Spinner
											animation="border"
											as="span"
											aria-hidden="true"
											className="me-2"
										/>
									)}
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</main>
	);
};

export default RegisterVerify;
