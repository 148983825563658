import { atom } from "recoil";

type FilterStateType = {
	showUpdatesOnly: boolean;
	searchTerm: string;
};

const FilterState = atom<FilterStateType>({
	key: 'filterState',
	default: {
		showUpdatesOnly: false,
		searchTerm: '',
	}
});

export {
	FilterState,
}
