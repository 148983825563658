import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card } from 'react-bootstrap';

const Footer = () => {
	const currentYear = moment().get("year");

	return (
		<footer className="footer section py-5 px-2">
			<Row>
				<Col xs={12} className="mb-4 mb-lg-0">
					<p className="text-center text-xl-left">
						Copyright © 2020-{`${currentYear} `}
						<Card.Link href="https://www.mobilistics.de" target="_blank" className="text-blue text-decoration-none fw-normal">
							Mobilistics GmbH
						</Card.Link>
					</p>
				</Col>
			</Row>
		</footer>
	);
};

export default Footer;
