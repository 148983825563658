import { atom } from "recoil";
import { Auth } from "../types/Auth";

const ROLES = {
	ADMIN: 1,
	EDITOR: 3,
	USER: 5
}

const AuthState = atom<Auth>({
	key: 'authState',
	default: {
		username: '',
		email: '',
		roles: [],
		jwt: null
	}
});

export {
	ROLES,
	AuthState,
}
